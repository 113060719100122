@import '../global/bucket-styles';
@import '../global/style-guide.scss';

.specificError,
.questionsCallback,
.shopByCategory,
.moreWaysToShop {
	box-sizing: border-box;
	margin: 0 auto;
	width: 920px;
}

#lpContainer {
	margin: 0 auto;
	max-width: 1440px;
	min-width: 960px;
	width: calc(100% - 60px);
}

.shopByCategory {
	.bucket4Across {
		text-align: center;

		a {
			margin-left: 0;
		}
	}
}

#recentlyViewedContainer.recentlyMoreLikeThisLink .moreLikeThisLink {
	font-size: 1rem;
	font-weight: normal;
	padding: 3px;
	width: calc(100% - 20px);
	white-space: nowrap;
	@media screen and (max-width: 1024px) and (max-width: 1130px) {
		padding: 4px;
	}

	@media screen and (min-width: 1131px) and (max-width: 1230px) {
		font-size: 1.15rem;
		padding: 3px;
	}

	@media screen and (min-width: 1231px) and (max-width: 1325px) {
		font-size: 1.2rem;
		padding: 4px;
	}

	@media screen and (min-width: 1326px) {
		font-size: 1.15rem;
		padding: 6px;
		width: calc(100% - 38px);
	}
}

.moreWaysToShop {
	.bucket3Across a {
		padding: 23px 9px;
	}
}

.errorHeader {
	padding: 46px 19px 0;
	position: relative;

	.mainHead {
		color: #000;
		font-family: var(--fontHeading);
		font-size: 40px;
		font-weight: 400;
		margin: 0 0 20px;
	}

	.paraError {
		font-size: 1.18em
	}

	.errorHeading {
		span {
			padding: 1px 8px;

			&::before {
				content: "\A";
				white-space: pre;
			}
		}
	}

	.errorContainer {
		column-gap: 8%;
		display: flex;
		justify-content: center;
		padding: 15px 10px;

		.emailErrorValidationMessage {
			background: #ffebeb;
			border: 1px solid #c00;
			bottom: calc(100% - 1px);
			color: #c00;
			font-size: 1.3rem;
			font-weight: 500;
			line-height: 1.3em;
			padding: 5px 6px;
			position: absolute;
			width: 265px;
		}

		#emailSubscribeBtnError {
			margin-top: 8px;
			width: 154px;
		}

		#txtEmailUpdateRequest {
			appearance: none;
			border: 1px solid #adaeaf;
			border-radius: 5px;
			height: 30px;
			line-height: var(--baseLineHeight);
			padding: 0 0 0 5px;
			width: 279px;
		}

		.stayConnected {
			color: #333;
			font-family: var(--fontHeading);
			font-size: 1.75rem;
			font-weight: 500;
			margin-top: 2px;
		}

		.dealsInspiration {
			font-size: 1.18em;
			margin: 16px 0 6px;
		}
	}

}

.hpBucketWrapper {
	@extend %bucketStyles;
}

.categoryWrapper {
	margin: 0 28px 28px;
}

#pdMoreYouMayLike.moreYouLikeBorder {
	border-top: none;

	&.loaded {
		margin: 48px 0 78px;
	}

}

#pdMoreYouMayLike.suggestedProductsContainer .collapsibleTitle {
	color: #000;
	font-size: 2rem;
	text-transform: capitalize;
}

.suggestedProductsContainer {

	&.sortMoreYouMayLike {
		margin: 20px 0 0;
		overflow: unset;
		padding: 14px 0 0;
		position: relative;

		.jsCertonaTitle {
			font-size: 2.2rem;
			margin: 0 0 10px;

			@media screen and (max-width: 1279px) {
				font-size: 2rem;
				margin: 0 0 25px;
			}
		}

		#moreYouMayLikeContainer {
			display: block;
			height: unset;
			margin: 0 -10px 0;
			padding: 0;

			.moreYouLikeLink {
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				padding: 3px;

				img {
					border-radius: 5px;
				}

				.moreYouLikeImageWrapper {
					width: 100%;
				}

				.moreYouLikebadgPosition {
					height: unset;
					width: unset;

					.moreYouLikeBadgeWrapper {
						position: relative;
					}
				}
			}
		}

		.moreYouLikeProductsName {
			font-size: 12px;
			font-weight: normal;
			line-height: 16px;
			text-align: left;
			word-break: break-all;

			span {
				display: -webkit-box;
				height: 32px;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				word-break: break-word;
			}
		}

		.moreLikePrice {
			font-size: 14px;
			margin: 5px 0;
			text-align: left;
			width: 100%;

			&.salePrice {
				color: var(--darkRed);
			}
		}

		a:hover {
			.productSummaryRating__reviewCount {
				color: #333;
			}
		}

		.pdSectionTitle {
			margin-bottom: 10px;
		}

		.hideVisibility {
			visibility: hidden;
		}

		.glider-slide {
			padding: 7px;
		}

		.goldReviewStars__star {
			--goldReviewStarsSize: 1.6rem;
			margin-right: 2px;
		}
	}

}

.specificError {
	margin-bottom: 40px;
	/* Min Height for layout of .questionsCallback .notFoundConsultant*/
	min-height: 140px;
	padding: 40px 340px 0 0;

	&.specificErrorOC{
		box-sizing:unset;
		margin: unset;
	}

	a.calloutBtn.alt1.xSmall.calloutBtnRadius{
		border-radius: 5px;
	}

	a.calloutBtn.alt1.xSmall.calloutBtnWhite{
		background-color: white;
		color: black;
		border: 1px solid #000;
		border-radius: 5px;
	}
}

.questionsCallback {
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #ccc;
	font-family: var(--fontHeading);
	font-weight: 500;
	height: 92px;
	line-height: 1;
	margin-bottom: 40px;
	padding: 35px 208px 0 0;
	position: relative;
	text-align: left;

	&--confirmation{
		border-bottom: 1px solid #ccc;
		border-top: none;
		font-weight: normal;
		padding: 0;
		width: unset;
		span{
			font-weight: 500;
		}
	}

	.phoneCall {
		font-size: 16px;
		margin-left: 0.5em;
	}

	.notFoundConsultant {
		background: #fff;
		bottom: 0;
		padding-right: 36px;
		position: absolute;
		right: 0;
	}

	.phoneIcon {
		position: relative;
		top: 2px;
	}

	.chatWrapperWithIcon {
		border: none;
		button {
			background: transparent;
			border: none;
			font-weight: 500;
			padding: 0;
			padding-left: 20px;
			text-transform: capitalize;
			width: 100%;
			
			::after {
				top: -1px;
			}
		}
	}
}

.sbcCategories {
	list-style: none;
	margin: 30px auto 40px;
	padding: 0;
	text-align: center;

	li {
		float: left;
		font-family: var(--fontHeading);
		font-weight: 500;
		line-height: 30px;
		margin: 0 0 30px 26px;
		width: 210px;

		&:first-child,
		&:nth-child(5n) {
			clear: left;
			margin-left: 0;
		}
	}

	a {
		display: block;
		text-decoration: none;
	}

	img {
		display: block;
	}
}

.waysToShop {
	list-style: none;
	margin: 30px 1px 20px;
	padding: 0;
	text-align: center;

	li {
		float: left;
		margin: 0 0 20px 18px;
		width: 294px;

		&:first-child,
		&:nth-child(4n) {
			clear: left;
			margin-left: 0;
		}
	}

	a {
		border: 1px solid #ccc;
		display: table-cell;
		height: 109px;
		text-decoration: none;
		vertical-align: middle;
		width: 292px;
	}

	strong {
		display: block;
		font-family: var(--fontHeading);
		font-size: 16px;
		font-weight: 500;
		line-height: 21px;
	}
}

.suggestedProductsContainer {
	border-top: 1px solid #ccc;
	margin-bottom: 40px;
	padding-top: 15px;

	&.suggestedProductsContainerOC{
		border-top:none;
	}
	.collapsibleTitle {
		color: #000;
	}
}

.notFoundContainer {
	margin: 0 auto;
	padding: 40px 0 0;
	width: 840px;

	h1 {
		font-size: 20px;
	}

	&.searchWrapper {
		text-align: center;
		overflow-wrap: break-word;

		h1 {
			font-weight: normal;
			line-height: 1.3;
			margin-bottom: 25px;
		}
	}
}

.notFoundNeedHelpContainer {
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #ccc;
	margin: 65px 0 0;
	padding: 8px 0;
	position: relative;

	h1 {
		background: #fff;
		position: absolute;
		top: -20px;
	}

	strong {
		color: #900;
		font-size: 14px;
	}

	#searchChat,
	#searchCallback {
		display: inline-block;
		vertical-align: middle;
	}

	.chatButtonContainer {
		position: relative;
	}

	&.searchInner {
		border: 0;
		margin: 0 0 40px;
		padding: 0;
		text-align: center;

		.notFoundLine {
			font-size: 18px;

			strong {
				font-size: inherit;
			}
		}
	}
}

.notFoundLine {
	display: inline-block;
	vertical-align: middle;

	strong {
		color: #000;
	}
}

.notFoundCosultant {
	bottom: 0;
	position: absolute;
	right: 0;
}

.certonaHdr {
	display: block;
	font-size: 20px;
	font-weight: 500;
}

#bdProdDetail .notFoundNeedHelpContainer {
	margin-top: 71px;
}

/* Applies to nosearch_rr, and nosearchsku_rr (and GF versions) Certona Schemes in order to overwrite default/inherited Certona styles.*/
.suggestedProductsContainer[data-scheme*='nosearch'] {
	#certonaItems {
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		width: 100%;

		.resultContainer {
			border-bottom: 1px solid #ccc;
			box-sizing: inherit;
			flex: 0 1 289px;
			margin-top: 30px;
			padding-bottom: 15px;
			position: relative;

			a {
				text-decoration: none;

				&:hover {
					color: #000;
				}
			}
		}

		.sortCallout3 {
			transform: translateY(-3px);
		}

		.resultProdPrice {
			font-size: 16px;
			font-weight: 500;
			margin-top: 10px;
		}
	}

	.resultImgContainer {
		display: flex;
		margin-bottom: 15px;
	}
}

.boldChatAlignment {
	margin-left: 5px;
	vertical-align: middle;
}

/* Sort Item Text Callouts */
.sortCallout1,
/* 16+ Colors */
.sortCallout2,
/* Sold Out */
.sortCallout3,
/* More Options */
.sortCallout4,
/* Daily Sale */
.sortCallout5,
/* n Left with Daily Sale */
.sortCallout6,
/* n Left (alone) */
.sortCallout7

/* 100+ Colors */
	{
	background: #f1f1f1;
	background: rgba(241, 241, 241, 0.9);
	color: #444;
	cursor: pointer;
	font-size: 11px;
	font-weight: 500;
	height: 30px;
	line-height: 16px;
	padding: 5px 0;
	position: absolute;
	right: 18px;
	text-align: center;
	top: 237px;
	width: 50px;
}

.sortCallout3 {
	bottom: 0;
	right: 16px;

}

.sortCallout1.floorLamp,
.sortCallout3.floorLamp,
.sortCallout7.floorLamp {
	top: 298px;
}

.sortCallout1,
.sortCallout7 {
	font-weight: 500;
	height: 30px;
	padding: 5px 0;

	strong {
		display: block;
		font-size: 18px;
	}
}

.sortCallout2,
.sortCallout4 {
	background: #000;
	background: rgba(0, 0, 0, 0.9);
	color: #fff;
	font-size: 12px;
	height: auto;
	line-height: 1;
	padding: 3px 0 2px;
	text-transform: uppercase;
	top: 208px;
}

.sortCallout2.floorLamp,
.sortCallout4.floorLamp {
	top: 308px;
}

.ie9 .sortCallout3 {
	height: 26px;
	padding: 7px 0;
}

.sortCallout4 {
	background-color: #d11919;
}

.sortCallout5,
.sortCallout6 {
	font-size: 12px;
	height: auto;
	line-height: 26px;
	padding: 0;
	top: 183px;
}

.sortCallout5.floorLamp {
	top: 283px;
}

.sortCallout6 {
	top: 211px;
}

.sortCallout6.floorLamp {
	top: 311px;
}

.sortPortfolioConfirmation {
	background: #fff;
	color: #900;
	display: none;
	font-size: 18px;
	height: 248px;
	left: 0;
	line-height: 20px;
	padding: 100px 10px 0;
	position: absolute;
	text-align: center;
	top: 0;
	width: 312px;
	z-index: 2;
}

.salePrice {
	color: var(--darkRed);
}


body.initialLoading {
	.lpCartMoreYouMayLike {
		visibility: hidden;
	}
}
 
.lpCartMoreYouMayLike {
	background: #fff;
	display: flex;
	flex-grow: 1;
	margin-top: 40px;
	min-width: 960px;
 
	#lblCartMoreYouMayLike {
		margin-top: auto;
		width: 100%;
	}
}

#lblCartMoreYouMayLike {
	.cartMoreYouMayLikeWrapper {		
		padding-top: 10px;

		.sortMoreYouMayLikeTitle {
			float: left;
			font: normal 2rem var(--fontHeading);
			line-height: 28px;
			letter-spacing: -0.04em;
			margin: 0 5px 0 0;
			
			@media (min-width: 1280px){
				font-size: 2.2rem;
			}
		}



		&:not(.loaded) .viewAllRecentlyViewedBtn {
			display: none;
		}
	}
}

#cartMoreYouMayLikeContainer {
	margin-bottom: 0;
	margin: 25px -6px 0px -6px;
	padding-bottom: 15px;

	.slick-next {
		right: -10px;
	}

	.slick-prev {
		left: -10px;
	}

	.slick-track {
		margin-left: 0;
		margin-right: 0;
	}

	.collapsibleItem {
		width: 120px;
	}

	.moreLikeThisLink {
		border: 1px solid #999;
		box-sizing: border-box;
		display: inline-block;
		font-size: 1.4rem;
		line-height: 10px;
		margin-top: 5px;
		padding: 6px;
		text-decoration: none;
		text-transform: uppercase;
		width: 160px;
	}

	.sortTrendingProdContainer {
		a {
			padding: 0;
		}
	}
}

.lpCartMoreYouMayLike {
	.suggestedProductsContainer {
		#cartMoreYouMayLikeContainer {
			@media (min-width:768px) {
				.sortMYMLProdContainer {
					& a:last-child {
						font-weight: normal;
						margin: 5px 0;
						text-align: center;
					}
				}
			}
			.moreYouMayLikeItem {
				.productSummaryRating {
					margin: 5px 0;
					.goldReviewStars {
						justify-content: left;
						&.invisible {
							visibility: hidden;
						}
					}
				}
				a>span {
					font-weight: 500;
					margin: 5px 0px;
					text-align: left;
					&.salePrice {
						color: var(--darkRed);
					}
				}
				.productName {
					font-size: 12px;
					font-weight: normal;
					height: 35px;
					overflow: hidden;
				}
			}

			.slick-prev {
				left: unset;
				right: 44px;

				&:before {
					left: 16px;
				}
			}

			.slick-next {
				right: 4px;

				&:before {
					left: 8px;
				}
			}

			.slick-prev,
			.slick-next {
				background: #e8e8e8;
				border-radius: 50%;
				height: 30px;
				overflow: hidden;
				top: -52px;
				width: 30px;

				&.slick-disabled {
					opacity: 0.3;
				}

				&:before {
					border-bottom: 2px solid #000;
					border-right: 2px solid #000;
					height: 8px;
					width: 8px;
				}
			}

			.slick-list .slick-slide {
				margin: 0px;
				padding: 3px;
				text-align: left;
				a {
					margin: 4px;
				}
			}

			.slick-slider .slick-track {
				margin-left: unset;
				margin-right: unset;
			}

			.goldReviewStars__star {
				height: 1.5rem;
				width: 1.5rem;
			}
		}
	}
}

.questionsCallback--confirmation + .lpCartMoreYouMayLike{
	margin-top: -43px;
	.suggestedProductsContainerOC{
		#cartMoreYouMayLikeContainer{
			margin-top: 0;
		}
	}
}