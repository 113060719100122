/* Collapsible Item List */
.collapsible {
	padding-bottom: 16px;
	position: relative;
	&.collapsed {
		background: none;

		.collapsibleItems {
			display: none;
		}

		.collapsibleArrow {
			background-position: -36px 0;
		}
	}
	&.loaded {
		background: none;
	}
	&.borderTop {
		border-top: 1px solid var(--grey);
		padding-top: 15px;
	}
	&.borderBottom {
		border-bottom: 1px solid var(--grey);
	}
	&.collapsed,
	&.loaded { &:before { display: none; } }
	&:before {
		animation: spin 1.25s infinite linear;
		content: '\e960';
		font-family: 'lpIcons';
		font-size: 2.5em;
		position: absolute;
		text-align: center;
		top: 48%;
		width: 100%;
		z-index: -1;
	}
}
//@extend-elements
//original selectors
//.collapsibleTitle, .collapsibleToggle
%extend_collapsibleCopy {
	color: #666;
	cursor: pointer;
	font-size: 13px;
}

.collapsibleItems {
	margin-top: 13px;
	padding: 0 25px;
}

.collapsibleItem {
	float: left;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	width: 240px;

	&:first-child {
		border-left: 0;
	}

	a {
		display: block;
		padding-bottom: 10px;
	}

	img {
		display: block;
		margin: 0 auto;
	}
}

.collapsibleTitle {
	@extend %extend_collapsibleCopy;
	cursor: pointer;
	font-family: var(--fontHeading);
	font-size: 1.4rem;
	font-weight: 500;
	text-transform: uppercase;
}

.grid4 {
	@extend .collapsibleItems;
	display: flex;
	height: 190px;
	justify-content: space-between;
	padding: 0;

	.collapsibleItem {
		@extend .collapsibleItem;
		border-left: 0;
		height: 190px;
		width: 240px;

		a {
			height: 166px;
		}
	}
}

.grid5 {
	@extend .collapsibleItems;
	height: 178px;

	.collapsibleItem {
		@extend .collapsibleItem;
		height: 190px;
		width: 181px;

		a {
			height: 166px;
		}
	}
}

.grid8 {
	@extend .collapsibleItems;
	height: 128px;

	.collapsibleItem {
		@extend .collapsibleItem;
		height: 128px;
		width: 112px;

		a {
			height: 112px;
		}
	}
}

.collapsibleToggle {
	@extend %extend_collapsibleCopy;

	&.right {
		position: absolute;
		right: 0;
		top: 8px;
	}
}

.collapsibleArrow {
	background-position: -18px 0;
	height: 17px;
	margin-left: 2px;
	width: 18px;
}